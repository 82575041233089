import { axios } from '@/utils/request'

/**
 * 用户注册
 * params{userName:"string",passwd:"string"}
 */
export function register(params) {
  return axios({
    url: '/api/v1/bg/register',
    method: 'post',
    data: params
  })
}

/**
 * 用户登录
 * params{userName:"string",passwd:"string"}
 */
export function login(params) {
  return axios({
    url: `/api/v1/bg/login`,
    method: 'post',
    data: params
  })
}
