<template>
  <div class="userdetail">
    <a-card title="用户信息">
      <a-row>
        <a-col :span="8">
          <p>用户ID</p>
          <div>{{ (data && data._id) || '' }}</div>
        </a-col>
        <a-col :span="8">
          <p>绑定邮箱</p>
          <div>{{ (data && data.email) || '无' }}</div>
        </a-col>
        <a-col :span="8">
          <p>注册时间</p>
          <div>{{ (data.createdAt && $comsys.dateformat(data.createdAt)) || '' }}</div>
        </a-col>
      </a-row>
      <a-row style="margin-top:20px">
        <a-col :span="8">
          <p>订单数量</p>
          <div>{{ (data && data.orderNum) || 0 }}</div>
        </a-col>
        <a-col :span="8">
          <p>消费总额</p>
          <div>￥{{ (data && (data.costMoney / 100).toFixed(2)) || 0 }}</div>
        </a-col>
        <a-col :span="8">
          <p>联系电话</p>
          <div>{{ (data && data.phone) || '' }}</div>
        </a-col>
      </a-row>
    </a-card>
    <a-card title="订单列表" style="margin-top:20px">
      <a-table :columns="columns" rowKey="_id" :pagination="pagination" :dataSource="tabledata">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="orderType" slot-scope="text">
          {{ text === 1 ? '微信' : '支付宝' }}
        </template>
        <template slot="email" slot-scope="text, record">
          {{ (record.user && record.user.email) || '--' }}
        </template>
        <template slot="price" slot-scope="text"> ￥ {{ (text && (text / 100).toFixed(2)) || 0 }} </template>
        <template slot="createdAt" slot-scope="text">
          {{ $comsys.dateformat(text) }}
        </template>

        <span slot="action" slot-scope="record">
          <a @click="hanleSeeDetails(record)">订单详情</a>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getUserDetail, getOrderList } from '../../../services/index'
const columns = [
  {
    title: '订单ID',
    dataIndex: '_id',
    key: '_id'
  },
  {
    title: '购买邮箱',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' }
  },
  {
    title: '支付方式',
    dataIndex: 'orderType',
    key: 'orderType',
    scopedSlots: { customRender: 'orderType' }
  },
  {
    title: '金额',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'createdAt' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: 'UserDetail',
  data() {
    return {
      id: this.$route.params.id,
      data: {},
      columns,
      tabledata: [],
      pagination: {
        pageSize: 10, // 默认每页显示数量
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      }
    }
  },
  mounted() {
    this.getDetail()
    this.getList({
      type: 1,
      id: this.id
    })
  },
  methods: {
    getDetail() {
      getUserDetail(this.id)
        .then(res => {
          if (res.code === 0) {
            this.data = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getList(params) {
      getOrderList(params).then(res => {
        if (res.code === 0) {
          this.tabledata = res.data.list
          this.pagination.total = res.data.count
        }
      })
    },
    pageChange(page, pageSize) {
      this.getList({ currentPage: page, type: 1 })
    }
  }
}
</script>
<style lang="less" scoped>
.userdetail {
  padding: 24px;
}
</style>
