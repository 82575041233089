<template>
  <div class="prolist">
    <page-view></page-view>
    <div class="prolist-content">
      <a-form layout="inline" :form="form" @submit="handleSubmit">
        <a-row type="flex" justify="space-between">
          <a-col :span="18">
            <a-form-item label="作品ID">
              <a-input type="text" v-decorator="['_id']" placeholder="请输入作品ID"></a-input>
            </a-form-item>
            <a-form-item label="店铺名称">
              <a-input type="text" v-decorator="['storeName']" placeholder="请输入店铺名称"></a-input>
            </a-form-item>
            <a-form-item label="作品名称">
              <a-input type="text" v-decorator="['productionName']" placeholder="请输入作品名称"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="3.5">
            <a-form-item>
              <a-button type="primary" html-type="submit">查询</a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="default" @click="handleReset">重置</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top:20px"
        :columns="columns"
        :dataSource="data"
        rowKey="createdAt"
        :pagination="pagination"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="storeName" slot-scope="text, record">
          {{ (record.store && record.store.storeName) || '' }}
        </template>
        <template slot="price" slot-scope="text"> ￥ {{ (text / 100).toFixed(2) }} </template>
        <template slot="type" slot-scope="text">
          <span v-if="text === -3" style="color:red">下架</span>
          <span v-if="text === -2" style="color:orange">草稿</span>
          <span v-else-if="text === -1" style="color:red">审核不通过</span>
          <span v-else-if="text === 0" style="color:#87d068">待审核</span>
          <span v-else-if="text === 1" style="color:#2db7f5">审核通过</span>
        </template>
        <template slot="isRecommend" slot-scope="text, record">
          <span v-if="text && record.type === 1">是</span>
          <span v-else-if="!text && record.type === 1">否</span>
          <span v-else>——</span>
        </template>
        <template slot="createdAt" slot-scope="text">
          {{ $comsys.dateformat(text) }}
        </template>
        <template slot="action" slot-scope="record">
          <a
            v-if="record.isRecommend && record.type === 1"
            @click="handleIsRecommend(record, 0)"
            style="margin-right:10px;color:#f00;"
          >
            撤销推荐
          </a>
          <a v-else @click="handleIsRecommend(record, 1)" style="margin-right:10px;">作品推荐</a>
          <router-link :to="`/listdetail/${record._id}`">
            <a>查看详情</a>
          </router-link>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import { getProductionList, updateProduct } from '../../services/index'
const columns = [
  {
    title: '作品ID',
    dataIndex: '_id',
    key: '_id',
    width: 240
  },
  {
    title: '店铺名称',
    dataIndex: 'storeName',
    key: 'storeName',
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'storeName' }
  },
  {
    title: '售价',
    dataIndex: 'price',
    key: 'price',
    width: 120,
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '作品名称',
    dataIndex: 'proName',
    key: 'proName',
    ellipsis: true
  },
  {
    title: '作品状态',
    dataIndex: 'type',
    key: 'type',
    width: 90,
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '是否推荐',
    dataIndex: 'isRecommend',
    key: 'isRecommend',
    width: 90,
    scopedSlots: { customRender: 'isRecommend' }
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
    scopedSlots: { customRender: 'createdAt' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'List',
  components: {
    PageView
  },
  data() {
    return {
      form: this.$form.createForm(this),
      value: 1,
      data: [],
      columns,
      pagination: {
        pageSize: 10, // 默认每页显示数量
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(params) {
      getProductionList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagination.total = res.data.count
        }
      })
    },
    handleIsRecommend(item, type) {
      if (item.type !== 1) {
        return this.$message.error('该作品未通过审核')
      }
      const params = {
        id: item._id,
        isRecommend: type === 1
      }
      updateProduct(params).then(res => {
        if (res.code === 0) {
          this.$message.success('成功')
          this.getList()
        }
      })
    },
    // 重置搜索内容
    handleReset() {
      this.form.resetFields()
      this.getList()
    },
    // 页数变化
    pageChange(page, pageSize) {
      this.getList({ currentPage: page })
    },
    // 查询
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const params = {}
        if (values._id) {
          params.id = values._id
        }
        if (values.storeName) {
          params.storeName = values.storeName
        }
        if (values.productionName) {
          params.productionName = values.productionName
        }
        this.getList(params)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.prolist {
  background-color: #f0f0f0;
  &-content {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
  }
}
</style>
