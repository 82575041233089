<template>
  <div class="pcheck">
    <page-view></page-view>
    <div class="pcheck-content">
      <a-form layout="inline" :form="form" @submit="handleSubmit">
        <a-row type="flex" justify="space-between">
          <a-col :span="18">
            <a-form-item label="作品ID">
              <a-input type="text" v-decorator="['id']" placeholder="请输入作品ID" />
            </a-form-item>
            <a-form-item label="店铺名称">
              <a-input type="text" v-decorator="['storeName']" placeholder="请输入店铺名称" />
            </a-form-item>
            <a-form-item label="作品名称">
              <a-input type="text" v-decorator="['productionName']" placeholder="请输入作品名称" />
            </a-form-item>
          </a-col>
          <a-col :span="3.5">
            <a-form-item>
              <a-button type="primary" html-type="submit">查询</a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="default" @click="handleReset">重置</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top:20px"
        :columns="columns"
        :dataSource="data"
        rowKey="createdAt"
        :pagination="pagination"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="storeName" slot-scope="text, record">
          {{ (record.store && record.store.storeName) || '' }}
        </template>
        <template slot="price" slot-scope="text"> ￥ {{ (text / 100).toFixed(2) }} </template>
        <template slot="createdAt" slot-scope="text">
          {{ $comsys.dateformat(text) }}
        </template>
        <template slot="action" slot-scope="record">
          <router-link :to="`/checkdetail/${record._id}`">
            <a>作品审核</a>
          </router-link>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import { getProductionList } from '../../services/index'
const columns = [
  {
    title: '作品ID',
    dataIndex: '_id',
    key: '_id',
    width: 240
  },
  {
    title: '店铺名称',
    dataIndex: 'storeName',
    key: 'storeName',
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'storeName' }
  },
  {
    title: '售价',
    dataIndex: 'price',
    key: 'price',
    width: 120,
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '作品名称',
    dataIndex: 'proName',
    key: 'proName',
    ellipsis: true
  },
  {
    title: '提交时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
    scopedSlots: { customRender: 'createdAt' }
  },
  {
    title: '操作',
    key: 'action',
    width: 110,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Check',
  components: {
    PageView
  },
  data() {
    return {
      form: this.$form.createForm(this),
      value: 1,
      data: [],
      columns,
      pagination: {
        pageSize: 10, // 默认每页显示数量
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      }
    }
  },
  mounted() {
    this.getList({ type: 0 })
  },
  methods: {
    getList(params) {
      getProductionList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagination.total = res.data.count
        }
      })
    },
    // 页数变化
    pageChange(page, pageSize) {
      this.getList({ currentPage: page, type: 0 })
    },
    // 重置搜索内容
    handleReset() {
      this.form.resetFields()
      this.getList({ type: 0 })
    },
    // 查询
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const params = { type: 0 }
        if (values.id) {
          params.id = values.id
        }
        if (values.storeName) {
          params.storeName = values.storeName
        }
        if (values.productionName) {
          params.productionName = values.productionName
        }
        this.getList(params)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.pcheck {
  background-color: #f0f0f0;
  &-content {
    display: block;
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
  }
}
</style>
