<template>
  <div class="shoplist">
    <page-view></page-view>
    <div class="shoplist-content">
      <a-form layout="inline" :form="form" @submit="handleSubmit">
        <a-row type="flex" justify="space-between">
          <a-col :span="18">
            <a-form-item label="店铺ID">
              <a-input type="text" v-decorator="['id']" placeholder="请输入店铺ID" />
            </a-form-item>
            <a-form-item label="店铺名称">
              <a-input type="text" v-decorator="['storeName']" placeholder="请输入店铺名称" />
            </a-form-item>
            <a-form-item label="邮箱">
              <a-input type="text" v-decorator="['email']" placeholder="请输入邮箱" />
            </a-form-item>
          </a-col>
          <a-col :span="3.5">
            <a-form-item>
              <a-button type="primary" html-type="submit">查询</a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="default" @click="handleReset">重置</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table style="margin-top: 20px" :columns="columns" :dataSource="data" rowKey="storeId" :pagination="pagination">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="type" slot-scope="type">
          <span v-if="type === 1">正常</span>
          <span v-else>异常</span>
        </span>
        <span slot="action" slot-scope="record">
          <a @click="handleLookDetail(record._id)">查看详情</a>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getStoreList } from '../../services/index'
import { PageView } from '@/layouts'
const columns = [
  {
    title: '店铺ID',
    dataIndex: 'storeId',
    key: 'storeId'
  },
  {
    title: '店铺名称',
    dataIndex: 'storeName',
    key: 'storeName'
  },
  {
    title: '电话',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: '店铺作品',
    dataIndex: 'production',
    key: 'production'
  },
  {
    title: '店铺状态',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'List',
  components: {
    PageView
  },
  data() {
    return {
      form: this.$form.createForm(this),
      value: 1,
      data: [],
      columns,
      pagination: {
        pageSize: 10, // 默认每页显示数量
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 查询
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const params = { id: '', storeName: '', email: '' }
        if (values.id) {
          params.id = values.id
        }
        if (values.storeName) {
          params.storeName = values.storeName
        }
        if (values.email) {
          params.email = values.email
        }
        this.getList(params)
      })
    },
    getList(params) {
      getStoreList(params)
        .then((res) => {
          if (res.code === 0) {
            this.data = res.data.list
            this.pagination.total = res.data.count
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 重置搜索内容
    handleReset() {
      this.form.resetFields()
      this.getList()
    },
    // 页数变化
    pageChange(page, pageSize) {
      this.currentPage = page
      this.getList({ currentPage: page })
    },
    // 页面跳转
    handleLookDetail(id) {
      this.$router.push(`/shopdetail/${id}`)
    }
  }
}
</script>
<style lang="less" scoped>
.shoplist {
  background-color: #f0f0f0;
  &-content {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
  }
}
</style>
