// eslint-disable-next-line
import { UserLayout, BasicLayout, RouteView, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/dashboard/Analysis',
    children: [
      // dashboard
      {
        path: '/dashboard/Analysis',
        name: 'dashboard',
        redirect: '/dashboard/Analysis',
        component: RouteView,
        meta: { title: '数据统计', keepAlive: false, icon: bxAnaalyse, permission: ['dashboard'] },
        children: [
          {
            path: '/dashboard/Analysis',
            name: 'Analysis',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Analysis'),
            meta: { title: '整体统计', keepAlive: false, permission: ['dashboard'] }
          }
        ]
      },

      // user
      {
        path: '/userlist',
        redirect: '/userlist',
        component: PageView,
        meta: { title: '用户管理', icon: 'user', permission: ['user'] },
        children: [
          {
            path: '/userlist',
            name: 'UserList',
            component: () => import(/* webpackChunkName: "userlist" */ '@/views/userlist/UserList'),
            meta: { title: '用户列表', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/userdetail/:id',
            name: 'UserDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "userlist" */ '@/views/userlist/detail/UserDetail'),
            meta: { title: '用户详情', keepAlive: true, permission: ['user'] }
          }
        ]
      },
      // shop
      {
        path: '/shop',
        name: 'shop',
        component: RouteView,
        redirect: '/shop/shoplist',
        meta: { title: '店铺管理', icon: 'table', permission: ['shop'] },
        children: [
          {
            path: '/shop/shoplist',
            name: 'List',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/ShopList'),
            meta: { title: '店铺列表', keepAlive: true, permission: ['shop'] }
          },
          {
            path: '/shopdetail/:id',
            name: 'ShopDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/detail/ShopDetail'),
            meta: { title: '店铺详情', keepAlive: true, permission: ['shop'] }
          }
        ]
      },

      // production
      {
        path: '/production',
        name: 'production',
        component: RouteView,
        redirect: '/production/list',
        meta: { title: '作品管理', icon: 'profile', permission: ['production'] },
        children: [
          {
            path: '/production/check',
            name: 'ProductionCheck',
            component: () => import(/* webpackChunkName: "production" */ '@/views/production/Check'),
            meta: { title: '作品审核', permission: ['production'] }
          },
          {
            path: '/production/list',
            name: 'ProductionList',
            component: () => import(/* webpackChunkName: "production" */ '@/views/production/ProductionList'),
            meta: { title: '作品列表', permission: ['production'] }
          },
          {
            path: '/checkdetail/:id',
            name: 'ProductDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "production" */ '@/views/production/detail/CheckDetail'),
            meta: { title: '审核详情', keepAlive: true, permission: ['production'] }
          },
          {
            path: '/listdetail/:id',
            name: 'ProductDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "production" */ '@/views/production/detail/ListDetail'),
            meta: { title: '列表详情', keepAlive: true, permission: ['production'] }
          }
        ]
      },

      // orders
      {
        path: '/orders',
        name: 'orders',
        component: PageView,
        redirect: '/orders/list',
        meta: { title: '订单管理', icon: 'check-circle-o', permission: ['orders'] },
        children: [
          {
            path: '/orders/list',
            name: 'OrdersList',
            component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/OrdersList'),
            meta: { title: '订单列表', keepAlive: false, permission: ['orders'] }
          },
          {
            path: '/ordersdetail/:id',
            name: 'OrdersDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/detail/OrdersDetail'),
            meta: { title: '订单详情', keepAlive: true, permission: ['orders'] }
          }
        ]
      },
      // money
      // {
      //   path: '/money',
      //   name: 'money',
      //   component: PageView,
      //   redirect: '/money/bill',
      //   meta: { title: '资金管理', icon: 'warning', permission: ['money'] },
      //   children: [
      //     {
      //       path: '/money/bill',
      //       name: 'MoneyBill',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/money/Bill'),
      //       meta: { title: '资金流水', permission: ['money'] }
      //     },
      //     {
      //       path: '/money/finalreport;',
      //       name: 'MoneyFinalReport',
      //       component: () => import(/* webpackChunkName: "fail" */ '@/views/money/FinalReport'),
      //       meta: { title: '店铺结算', permission: ['money'] }
      //     }
      //   ]
      // },

      // operation
      {
        path: '/operation',
        component: RouteView,
        redirect: '/operation/center',
        name: 'operation',
        meta: { title: '运营管理', icon: 'setting', keepAlive: true, permission: ['operation'] },
        children: [
          {
            path: '/operation/slideshow',
            name: 'slideshow',
            component: () => import(/* webpackChunkName: "operation" */ '@/views/operation/Slideshow'),
            meta: { title: '轮播图配置', keepAlive: true, permission: ['operation'] }
          },
          {
            path: '/operation/announcement',
            name: 'Announcement',
            component: () => import(/* webpackChunkName: "operation" */ '@/views/operation/Announcement'),
            meta: { title: '公告消息', keepAlive: true, permission: ['operation'] }
          },
          {
            path: '/operation/article',
            name: 'artice',
            component: () => import(/* webpackChunkName: "operation" */ '@/views/operation/Article'),
            meta: { title: '平台文章', keepAlive: false, permission: ['operation'] }
          },
          {
            path: '/announcementdetail/:id',
            name: 'OrdersDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "operation" */ '@/views/operation/detail/AnnouncementDetail'),
            meta: { title: '消息详情', keepAlive: false, permission: ['operation'] }
          },
          {
            path: '/articledetail/:id',
            name: 'ArticleDetail',
            hidden: true,
            component: () => import(/* webpackChunkName: "operation" */ '@/views/operation/detail/ArticleDetail'),
            meta: { title: '文章详情', keepAlive: true, permission: ['operation'] }
          },
          {
            path: '/publishArticle',
            name: 'PublishArticle',
            hidden: true,
            component: () => import(/* webpackChunkName: "operation" */ '@/views/operation/PublishArticle'),
            meta: { title: '文章编辑', keepAlive: true, permission: ['operation'] }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404')
  }
]
