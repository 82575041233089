<template>
  <div class="shopdetail">
    <page-view>
      <template slot="action">
        <a-button type="danger">禁用店铺</a-button>
      </template>
    </page-view>
    <div class="shopdetail-content">
      <a-card title="店铺信息">
        <a-row>
          <a-col :span="8">
            <p>店铺ID</p>
            <div>{{ data.storeId || '' }}</div>
          </a-col>
          <a-col :span="8">
            <p>店铺名称</p>
            <div>{{ data.storeName || '' }}</div>
          </a-col>
          <a-col :span="8">
            <p>注册时间</p>
            <div>{{ (data.createdAt && $comsys.dateformat(data.createdAt)) || '' }}</div>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <p>作品数量</p>
            <div>{{ data.production || 0 }}</div>
          </a-col>
          <a-col :span="8">
            <p>售后QQ</p>
            <div>{{ data.contact || '' }}</div>
          </a-col>
          <a-col :span="8">
            <p>联系电话</p>
            <div>{{ data.phone || '' }}</div>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <p>店铺状态</p>
            <div>{{ data.type === -1 ? '禁用' : '正常' }}</div>
          </a-col>
          <a-col :span="16">
            <p>店铺介绍</p>
            <div>{{ data.intro || '' }}</div>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="账户信息" style="margin-top: 20px">
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <p>姓名</p>
            <div>{{ data.user || '' }}</div>
          </a-col>
          <a-col :span="16">
            <p>支付宝账号</p>
            <div>{{ data.contact || '' }}</div>
          </a-col>
        </a-row>
      </a-card>
      <a-tabs v-model="activeKey" @change="handleTabChange">
        <a-tab-pane tab="作品列表" key="1">
          <a-table :columns="columns1" rowKey="_id" :pagination="pagination" :dataSource="productTableData">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <template slot="price" slot-scope="text">
              {{ '￥' + (text && (text / 100).toFixed(2)) || 0 }}
            </template>
            <template slot="status" slot-scope="text">
              {{ text ? '已发布' : '未发布' }}
            </template>
            <template slot="createdAt" slot-scope="text">
              {{ (text && $comsys.dateformat(text)) || '' }}
            </template>
            <span slot="action" slot-scope="record">
              <router-link :to="`/listdetail/${record._id}`">
                <a>查看详情</a>
              </router-link>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="订单列表" key="2" forceRender>
          <a-table :columns="columns2" :pagination="pagination" rowKey="_id" :dataSource="orderTableData">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <!-- <span slot="customTitle">
              <a-icon type="smile-o" />
            </span> -->
            <template slot="price" slot-scope="text">
              {{ '￥' + (text && (text / 100).toFixed(2)) || 0 }}
            </template>
            <span slot="action" slot-scope="record">
              <router-link :to="`/ordersdetail/${record._id}`">
                <a>查看详情</a>
              </router-link>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane tab="结算列表" key="3">
          <a-table :columns="columns3" rowKey="_id" :pagination="pagination" :dataSource="settlementTabledata">
            <a slot="name" slot-scope="text">{{ text }}</a>
            <template slot="totalPrice" slot-scope="text">
              {{ '￥' + (text && (text / 100).toFixed(2)) || 0 }}
            </template>
            <template slot="ratios" slot-scope="text">
              {{ text * 100 + '%' }}
            </template>
            <template slot="resultPrice" slot-scope="text, record">
              {{ '￥' + (record.totalPrice && ((record.totalPrice * record.ratios) / 100).toFixed(2)) || 0 }}
            </template>
            <template slot="type" slot-scope="text">
              {{ text ? '已结算' : '待结算' }}
            </template>
            <template slot="note" slot-scope="text">
              {{ text ? text : '--' }}
            </template>
            <span slot="action" slot-scope="text, record">
              <a v-if="!record.type" @click="handleSettlement(record)">店铺结算</a>
              <span v-else>--</span>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
      <a-modal
        v-model="settlementVariable"
        :pagination="pagination"
        title="店铺结算"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <a-form id="pass-form" :form="form" v-bind="formItemLayout">
          <a-form-item label="备注">
            <a-textarea
              v-decorator="['note', { rules: [{ required: true, message: '信息备注!' }] }]"
              placeholder="信息备注"
              :rows="4"
              :maxLength="500"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import {
  getStoreDetail,
  getSettlement,
  getProductionByStore,
  getOrderByStore,
  updateSettlement
} from '../../../services/index'
const columns1 = [
  {
    title: '作品ID',
    dataIndex: '_id',
    key: '_id'
  },
  {
    title: '作品名称',
    dataIndex: 'proName',
    key: 'proName',
    ellipsis: true
  },
  {
    title: '售价',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
    width: 80
  },
  {
    title: '销售数量',
    dataIndex: 'sales',
    key: 'sales',
    width: 100
  },
  {
    title: '浏览数量',
    dataIndex: 'looks',
    key: 'looks',
    width: 100
  },
  {
    title: '作品状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    width: 100
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
    width: 200
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 100
  }
]
const columns2 = [
  {
    title: '子订单ID',
    dataIndex: '_id',
    key: '_id'
  },
  {
    title: '作品ID',
    dataIndex: 'production',
    key: 'production'
  },
  {
    title: '作品名称',
    dataIndex: 'proName',
    key: 'proName'
  },
  {
    title: '单价',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '数量',
    dataIndex: 'count',
    key: 'count'
  },
  {
    title: '金额',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: '结算金额',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
]
const columns3 = [
  {
    title: '年月',
    dataIndex: 'time',
    key: 'time'
  },
  {
    title: '销售数量',
    dataIndex: 'count',
    key: 'count'
  },
  {
    title: '销售总额',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    scopedSlots: { customRender: 'totalPrice' }
  },
  {
    title: '结算比例',
    dataIndex: 'ratios',
    key: 'ratios',
    scopedSlots: { customRender: 'ratios' }
  },
  {
    title: '结算金额',
    dataIndex: 'resultPrice',
    key: 'resultPrice',
    scopedSlots: { customRender: 'resultPrice' }
  },
  {
    title: '结算状态',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '结算备注',
    dataIndex: 'note',
    key: 'note',
    scopedSlots: { customRender: 'note' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: 'ShopDetail',
  components: {
    PageView
  },
  data() {
    return {
      id: this.$route.params.id,
      form: this.$form.createForm(this),
      data: {},
      columns1,
      columns2,
      columns3,
      orderTableData: [],
      settlementTabledata: [],
      productTableData: [],
      settlementVariable: false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      currentRow: {},
      pagination: {
        current: 1,
        hideOnSinglePage: false,
        pageSize: 10, // 默认每页显示数量
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      },
      activeKey: '1'
    }
  },
  mounted() {
    this.getDetail(this.id)
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.id = this.$route.params.id
        this.activeKey = '1'
        this.getDetail(to.params.id)
      }
    }
  },
  methods: {
    // 获取详情
    getDetail(id) {
      getStoreDetail(id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.getProductList({ data: {}, id: res.data._id })
        }
      })
    },

    // 获取作品列表
    getProductList(params) {
      getProductionByStore(params).then((res) => {
        if (res.code === 0) {
          const data = (res.data && res.data.list) || []
          this.productTableData = data
          this.pagination.total = res.data.count || 0
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取订单列表
    getOrderList(params) {
      getOrderByStore(params)
        .then((res) => {
          if (res.code === 0) {
            const data = (res.data && res.data.list) || []
            this.orderTableData = data
            this.pagination.total = res.data.count || 0
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取结算列表
    getSettlementList(params) {
      getSettlement(params)
        .then((res) => {
          if (res.code === 0) {
            const data = (res.data && res.data.statistic) || []
            this.settlementTabledata = data
            this.pagination.total = res.data.statistic.length || 0
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // tab切换
    handleTabChange(key) {
      const storeId = this.data._id
      this.pagination.current = 1
      this.pagination.total = 0
      this.activeKey = key
      const params = { data: { pageSize: 10 }, id: storeId }
      if (storeId) {
        switch (key) {
          case '1':
            this.getProductList(params)
            break
          case '2':
            this.getOrderList(params)
            break
          case '3':
            this.getSettlementList(params)
            break
          default:
            break
        }
      }
    },
    // 页数变化
    pageChange(page, pageSize) {
      const storeId = this.data._id
      this.pagination.current = page
      const params = { data: { pageSize: 10, currentPage: page }, id: storeId }
      if (storeId) {
        switch (this.activeKey) {
          case '1':
            this.getProductList(params)
            break
          case '2':
            this.getOrderList(params)
            break
          case '3':
            this.getSettlementList(params)
            break
          default:
            break
        }
      }
    },
    // 点击店铺结算
    handleSettlement(record) {
      this.currentRow = record
      this.settlementVariable = true
    },
    // 店铺确认结算
    handleOk(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            id: this.data._id,
            data: {
              startDate: this.currentRow.time,
              endDate: this.currentRow.time,
              note: values.note
            }
          }
          updateSettlement(params)
            .then((res) => {
              if (res.code === 0) {
                this.form.setFieldsValue({ note: '' })
                this.getSettlementList({ id: this.data._id })
                this.$message.success('结算成功')
              } else {
                this.$message.error(res.msg)
              }
            })
            .finally(() => {
              this.settlementVariable = false
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
    // 店铺取消结算
    handleCancel() {
      this.settlementVariable = false
      this.form.setFieldsValue({ note: '' })
    }
  }
}
</script>
<style lang="less" scoped>
.shopdetail {
  background-color: #f0f0f0;
  &-content {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
  }
}
</style>
