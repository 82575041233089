import { axios } from '@/utils/request'

/**
 * 获取用户列表
 */
export function getUserList(params) {
  return axios({
    url: `/api/v1/bg/user`,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 获取用户详情
 * id：用户ID
 */
export function getUserDetail(id) {
  return axios({
    url: `/api/v1/bg/user/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 获取店铺列表
 */
export function getStoreList(params = {}) {
  return axios({
    url: `/api/v1/bg/store`,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取店铺详情
 */
export function getStoreDetail(id) {
  return axios({
    url: `/api/v1/bg/store/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取作品列表
 */
export function getProductionList(params = {}) {
  return axios({
    url: `/api/v1/bg/production`,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取作品详情
 */
export function getProductionDetail(id) {
  return axios({
    url: `/api/v1/bg/production/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 *
 * @param {*} params
 * 更新作品
 */
export function updateProduct(params) {
  return axios({
    url: `/api/v1/bg/production/${params.id}`,
    method: 'put',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 创建轮播图
 * @param
 * { "cover": 轮播图片,
  "user": 操作用户,
  "title": 轮播图标题,
  "content":轮播图内容,
  "weight": 0,
  "topic": 0,
  "type": 状态
}params
 */
export function createCarousel(params) {
  return axios({
    url: '/api/v1/bg/carousel',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取轮播图列表
 */
export function getCarouselList() {
  return axios({
    url: `/api/v1/bg/carousel`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取轮播图详情
 */
export function getCarouselDetail(id) {
  return axios({
    url: `/api/v1/bg/production/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function deleteCarousel(id) {
  return axios({
    url: `/api/v1/bg/carousel/${id}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取公告信息列表
 */
export function getNoticeList() {
  return axios({
    url: `/api/v1/bg/notice`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取公告详情
 */
export function getNoticeDetail(id) {
  return axios({
    url: `/api/v1/bg/notice/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取订单列表
 */
export function getOrderList(params) {
  return axios({
    url: `/api/v1/bg/order`,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 获取订单详情
 */
export function getOrderDetail(id) {
  return axios({
    url: `/api/v1/bg/order/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取操作记录
 */
export function getOperaHistory(id) {
  return axios({
    url: `/api/v1/bg/operaHistory/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取操作记录
 */
export function createStore(params) {
  return axios({
    url: `/api/v1/store`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取操作记录
 */
export function getCosSign(params) {
  return axios({
    url: `/api/cosSign/${params.id}`,
    method: 'get',
    params: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 创建文章
 */
export function createArticle(params) {
  return axios({
    url: `/api/v1/bg/article`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取文章列表
 */
export function getArticleList(params) {
  return axios({
    url: `/api/v1/bg/article`,
    method: 'get',
    params: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取文章详情
 */
export function getArticleDetail(params) {
  return axios({
    url: `/api/v1/bg/article/${params.id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 更新文章
 */
export function updateArticle(params) {
  return axios({
    url: `/api/v1/bg/article/${params.id}`,
    method: 'put',
    data: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除文章
 */
export function deleteArticle(params) {
  return axios({
    url: `/api/v1/bg/article/${params.id}`,
    method: 'delete',
    data: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除文章
 */
export function getSettlement(params) {
  return axios({
    url: `/api/v1/bg/settlement/${params.id}`,
    method: 'get',
    params: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 根据店铺id 获取作品列表
 * @param {*} params
 */
export function getProductionByStore(params) {
  return axios({
    url: `/api/v1/bg/productionByStore/${params.id}`,
    method: 'get',
    params: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 根据店铺id 获取店铺订单列表
 */
export function getOrderByStore(params) {
  return axios({
    url: `/api/v1/bg/order/store/${params.id}`,
    method: 'get',
    params: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 更新结算
 */
export function updateSettlement(params) {
  return axios({
    url: `/api/v1/bg/settlement/${params.id}`,
    method: 'put',
    data: params.data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
