import Vue from 'vue'
import { logout } from '@/api/login'
import { login } from '@/services/user'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
const authrole = {
  id: 'admin',
  name: '管理员',
  describe: '拥有所有权限',
  status: 1,
  creatorId: 'system',
  createTime: 1497160610259,
  deleted: 0,
  permissions: [
    {
      roleId: 'admin',
      permissionId: 'dashboard',
      permissionName: '仪表盘',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'query',
          describe: '查询',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        },
        {
          action: 'delete',
          describe: '删除',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    },
    {
      roleId: 'admin',
      permissionId: 'shop',
      permissionName: '店铺管理',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'query',
          describe: '查询',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        },
        {
          action: 'delete',
          describe: '删除',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    },
    {
      roleId: 'admin',
      permissionId: 'production',
      permissionName: '作品管理',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'query',
          describe: '查询',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        },
        {
          action: 'delete',
          describe: '删除',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    },
    {
      roleId: 'admin',
      permissionId: 'orders',
      permissionName: '订单管理',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'import',
          describe: '导入',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    },
    {
      roleId: 'admin',
      permissionId: 'money',
      permissionName: '资金管理',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'query',
          describe: '查询',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        },
        {
          action: 'delete',
          describe: '删除',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    },
    {
      roleId: 'admin',
      permissionId: 'operation',
      permissionName: '运营管理',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'query',
          describe: '查询',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        },
        {
          action: 'delete',
          describe: '删除',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    },
    {
      roleId: 'admin',
      permissionId: 'user',
      permissionName: '用户管理',
      actions:
        '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"import","defaultCheck":false,"describe":"导入"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"},{"action":"export","defaultCheck":false,"describe":"导出"}]',
      actionEntitySet: [
        {
          action: 'add',
          describe: '新增',
          defaultCheck: false
        },
        {
          action: 'import',
          describe: '导入',
          defaultCheck: false
        },
        {
          action: 'get',
          describe: '详情',
          defaultCheck: false
        },
        {
          action: 'update',
          describe: '修改',
          defaultCheck: false
        },
        {
          action: 'delete',
          describe: '删除',
          defaultCheck: false
        },
        {
          action: 'export',
          describe: '导出',
          defaultCheck: false
        }
      ],
      actionList: null,
      dataAccess: null
    }
  ],
  permissionList: ['dashboard', 'shop', 'production', 'orders', 'money', 'operation', 'user', 'support']
}

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    user: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(response => {
            if (response.code === 0) {
              const result = response.data
              Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
              Vue.ls.set(USER_INFO, result.user, 7 * 24 * 60 * 60 * 1000)
              if (result.token && result.user._id) {
                commit('SET_TOKEN', result.token)
                commit('SET_INFO', result.user)
                commit('SET_NAME', { name: result.user.userName, welcome: welcome() })
                resolve()
              } else {
                reject(new Error('登录失败'))
              }
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        const role = { ...authrole }
        commit('SET_ROLES', role)
        resolve(role)
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise(resolve => {
        logout(state.token)
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
          .finally(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            Vue.ls.remove(ACCESS_TOKEN)
            Vue.ls.remove(USER_INFO)
          })
      })
    }
  }
}

export default user
