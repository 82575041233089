<template>
  <div class="userlist">
    <a-form layout="inline" :form="form" @submit="handleSubmit">
      <a-row type="flex" justify="space-between">
        <a-col :span="18">
          <a-form-item label="手机号">
            <a-input type="text" v-decorator="['phone']" placeholder="请输入手机号" />
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input type="text" v-decorator="['email']" placeholder="请输入邮箱" />
          </a-form-item>
        </a-col>
        <a-col :span="3.5">
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="default" @click="handleReset">重置</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table style="margin-top:20px" :columns="columns" :dataSource="data" rowKey="_id" :pagination="pagination">
      <a slot="name" slot-scope="text">{{ text || '暂无' }}</a>
      <template slot="isSeller" slot-scope="text">
        <span v-if="text">是</span>
        <span v-else>否</span>
      </template>
      <span slot="action" slot-scope="record">
        <a-popconfirm
          title="确认升级该用户为作者吗?"
          ok-text="是"
          cancel-text="否"
          @confirm="handleUpdateEditor(record)"
        >
          <a :disabled="record.isSeller ? true : false">升级为作者</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <router-link :to="`/userdetail/${record._id}`">
          <a>查看详情</a>
        </router-link>
      </span>
    </a-table>
  </div>
</template>

<script>
import { getUserList, createStore } from '../../services/index'
const columns = [
  {
    title: '用户ID',
    dataIndex: '_id',
    key: '_id'
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: '是否是作者',
    dataIndex: 'isSeller',
    key: 'isSeller',
    scopedSlots: { customRender: 'isSeller' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'UserList',
  data() {
    return {
      form: this.$form.createForm(this),
      value: 1,
      data: [],
      columns,
      currentPage: 1,
      pagination: {
        pageSize: 10, // 默认每页显示数量
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(params) {
      getUserList(params).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagination.total = res.data.count
        }
      })
    },
    handleUpdateEditor(item) {
      const params = {
        user: item._id,
        storeName: '默认店铺',
        accountName: '默认用户',
        AlipayAccount: item.phone || '',
        phone: item.phone || '',
        email: item.email || '',
        contact: item.contact || '',
        intro: '新开张小店'
      }
      createStore(params).then(res => {
        if (res.code === 0) {
          this.getList({ currentPage: this.currentPage })
          this.$message.success('创建成功')
        }
      })
    },
    // 页数变化
    pageChange(page, pageSize) {
      this.currentPage = page
      this.getList({ currentPage: page })
    },
    // 查询
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const params = {}
        if (values.phone) {
          params.phone = values.phone
        }
        if (values.email) {
          params.email = values.email
        }
        this.getList(params)
      })
    },
    // 重置搜索内容
    handleReset() {
      this.form.resetFields()
      this.getList()
    }
  }
}
</script>
<style lang="less" scoped>
.userlist {
  padding: 24px;
}
</style>
