<template>
  <div class="prodetail">
    <page-view>
      <template slot="action">
        <a-button type="primary" @click="handleEditPreview" style="margin-right:10px">
          <a-icon type="edit" />
          编辑预览
        </a-button>
        <a-button type="primary" style="margin-right:10px">
          <a :href="data.fileUrl" :download="data.proName || ''">作品下载</a>
        </a-button>
        <a-button type="primary" style="margin-right:10px">
          <a :href="data.preUrl" target="_blank">作品预览</a>
        </a-button>
        <a-button type="danger" @click="handleCancelProduct">撤销作品</a-button>
      </template>
    </page-view>
    <div class="prodetail-content">
      <a-row>
        <a-col :span="17">
          <a-card :title="`作品编号：${data._id}`">
            <span slot="extra">发布时间：{{ (data.createdAt && $comsys.dateformat(data.createdAt)) || '' }}</span>
            <div class="img-box">
              <img :src="data.cover || ''" alt="作品宣传图" />
              <h2 class="title">
                {{ data.proName }}
              </h2>
              <p class="price">￥{{ data.price }}</p>
              <div>
                <span v-for="tag in data.tags" :key="tag">
                  <a-tag>{{ tag }}</a-tag>
                </span>
                <span>
                  <a-tag v-if="data.version === 1">Axure8</a-tag>
                  <a-tag v-else-if="data.version === 1">Axure9</a-tag>
                </span>
              </div>
              <a-divider />
              <div class="detailcontent" v-html="data.content"></div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="7">
          <a-card title="操作记录" class="pro-action">
            <div v-for="item in actionList" :key="item._id" class="item">
              <p>{{ item.createdAt && $comsys.dateformat(item.createdAt) }}</p>
              <p v-if="item.type === -1">审核拒绝。拒绝原因：{{ item.note || '无' }}</p>
              <p v-else-if="item.type === -3">作品撤销。撤销原因：{{ item.note || '无' }}</p>
              <p v-else>{{ item.content }}</p>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-modal
        title="撤销作品"
        :visible="cancelvisible"
        :confirm-loading="confirmLoading"
        @ok="handleCancelOk"
        @cancel="handleCancel"
      >
        <a-form id="nopass-form" :form="form" v-bind="formItemLayout">
          <a-form-item label="撤销原因">
            <a-textarea
              v-decorator="['reason', { rules: [{ required: true, message: '请输入拒绝理由!' }] }]"
              placeholder="请输入拒绝理由"
              :rows="4"
            />
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea v-decorator="['note']" placeholder="信息备注" :rows="4" />
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        title="编辑预览"
        :visible="previewlvisible"
        :confirm-loading="confirmLoading"
        @ok="handlePreviewOk"
        @cancel="handlePreviewCancel"
      >
        <a-form id="pass-form" :form="form" v-bind="formItemLayout">
          <a-form-item label="预览链接">
            <a-input
              type="text"
              v-decorator="[
                'preUrl',
                { initialValue: this.data.preUrl, rules: [{ required: true, message: '请输入预览链接!' }] }
              ]"
              placeholder="请输入预览链接"
            />
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea
              v-decorator="['reason', { initialValue: this.data.reason || '' }]"
              placeholder="信息备注"
              :rows="4"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { PageView } from '@/layouts'
import { getProductionDetail, updateProduct, getOperaHistory } from '../../../services/index'

export default {
  name: 'ProductDetail',
  components: {
    PageView
  },
  data() {
    return {
      id: this.$route.params.id,
      data: {},
      form: this.$form.createForm(this),
      cancelvisible: false,
      confirmLoading: false,
      previewlvisible: false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      actionList: []
    }
  },
  mounted() {
    this.getDetail()
    this.getActionHistory()
  },
  methods: {
    // 获取作品详情
    getDetail() {
      getProductionDetail(this.id).then(res => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },
    // 获取操作记录
    getActionHistory() {
      getOperaHistory(this.id).then(res => {
        if (res.code === 0) {
          this.actionList = res.data
        }
      })
    },
    // 更新作品信息
    upDate(params) {
      updateProduct(params).then(res => {
        if (res.code === 0) {
          this.getDetail()
          this.getActionHistory()
          this.$message.success('成功')
          this.cancelvisible = false
          this.$router.push('/production/list')
        }
      })
    },

    // 撤销确定按钮
    handleCancelOk(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            id: this.data._id,
            type: -3,
            isRecommend: false,
            ...values
          }
          this.upDate(params)
        }
      })
    },
    // 撤销取消按钮
    handleCancel() {
      this.cancelvisible = false
    },

    // 撤销作品
    handleCancelProduct() {
      this.cancelvisible = true
    },

    // 编辑预览
    handleEditPreview() {
      this.previewlvisible = true
    },
    // 编辑预览确定
    handlePreviewOk() {
      this.previewlvisible = false
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            id: this.data._id,
            ...values
          }
          this.upDate(params)
        }
      })
    },
    // 编辑预览取消
    handlePreviewCancel() {
      this.form.resetFields({
        preUrl: '',
        note: ''
      })
      this.previewlvisible = false
    }
  }
}
</script>

<style lang="less">
.prodetail {
  background-color: #f0f0f0;
  .detailcontent {
    p {
      img {
        width: 100%;
      }
    }
  }
  .price {
    color: #f01b1b;
    font-size: 18px;
    font-weight: 600;
  }
  .img-box {
    width: 100%;
    img {
      width: 100%;
    }
    .title {
      margin-top: 24px;
    }
  }
  .pro-action {
    margin-left: 24px;
    min-height: 100vh;
    .item {
      margin-bottom: 15px;
      p {
        word-break: break-all;
      }
    }
  }
  &-content {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
  }
}
</style>
