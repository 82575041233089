<template>
  <div>
    <a-card title="订单信息">
      <a-row>
        <a-col :span="8">
          <p>订单ID：</p>
          <div>{{ detail._id || '' }}</div>
        </a-col>
        <a-col :span="8">
          <p>购买邮箱：</p>
          <div>
            {{ (detail.user && detail.user.email) || '' }}
          </div>
        </a-col>
        <a-col :span="8">
          <p>订单时间：</p>
          <div>
            {{ detail.createdAt && $comsys.dateformat(detail.createdAt) }}
          </div>
        </a-col>
      </a-row>
      <a-row style="margin-top:20px">
        <a-col :span="8">
          <p>手机号：</p>
          <div>
            {{ (detail.user && detail.user.phone) || '' }}
          </div>
        </a-col>
        <a-col :span="8">
          <p>用户ID：</p>
          <div>
            {{ (detail.user && detail.user._id) || '' }}
          </div>
        </a-col>
        <a-col :span="8">
          <p>用户名：</p>
          <div>
            {{ (detail.user && detail.user.userName) || '' }}
          </div>
        </a-col>
      </a-row>
      <a-row style="margin-top:20px">
        <a-col :span="8">
          <p>作品数量：</p>
          <div>{{ detail.childOrder.length ? detail.childOrder.length : detail.childOrder.length + 1 }}件</div>
        </a-col>
        <a-col :span="8">
          <p>支付方式：</p>
          <div>微信</div>
        </a-col>
        <a-col :span="8">
          <p>支付金额：</p>
          <div>{{ (detail.price && (detail.price / 100).toFixed(2)) || 2 }}</div>
        </a-col>
      </a-row>
    </a-card>
    <a-card title="订单信息" style="margin-top:24px">
      <a-table :columns="columns" :dataSource="orderChild">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="price" slot-scope="text"> ￥ {{ (text / 100).toFixed(2) }} </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getOrderDetail } from '@/services/index'
const columns = [
  {
    title: '子订单ID',
    dataIndex: '_id',
    key: '_id'
  },
  {
    title: '店铺ID',
    dataIndex: 'store',
    key: 'store'
  },
  {
    title: '店铺名称',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: '作品ID',
    dataIndex: 'count',
    key: 'count'
  },
  {
    title: '作品名称',
    dataIndex: 'proName',
    key: 'proName',
    width: 200
  },
  {
    title: '单价',
    dataIndex: 'price',
    key: 'price',
    width: 120,
    scopedSlots: { customRender: 'price' }
  }
  // {
  //   title: '数量',
  //   dataIndex: 'date',
  //   key: 'date'
  // },
  // {
  //   title: '金额',
  //   key: 'action'
  // }
]
export default {
  name: 'OrdersDetail',
  data() {
    return {
      id: this.$route.params.id,
      columns,
      detail: {},
      orderChild: []
    }
  },
  mounted() {
    console.log('作品id', this.$route.params)
    if (this.id) {
      this.getDetail(this.id)
    } else {
      this.$router.push('/404')
    }
  },
  methods: {
    getDetail(id) {
      getOrderDetail(id)
        .then(res => {
          if (res.code === 0) {
            this.detail = res.data
            if (res.data.childOrder) {
              this.orderChild = res.data.childOrder
            } else {
              this.orderChild = [{ ...res.data }]
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
