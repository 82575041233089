<template>
  <div class="OrdersList">
    <a-form layout="inline" :form="form" @submit="handleSubmit">
      <a-row type="flex" justify="space-between">
        <a-col :span="18">
          <a-form-item label="订单ID">
            <a-input type="text" v-decorator="['orderId']" placeholder="请输入订单ID"></a-input>
          </a-form-item>
          <a-form-item label="购买邮箱">
            <a-input type="text" v-decorator="['email']" placeholder="请输入购买邮箱"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="3.5">
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="default" @click="handleReset">重置</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table style="margin-top: 20px" rowKey="_id" :columns="columns" :dataSource="data" :pagination="pagination">
      <a slot="name" slot-scope="text">{{ text }}</a>
      <template slot="count" slot-scope="text, record">
        {{ record.childOrder.length ? record.childOrder.length : record.childOrder.length + 1 }}
      </template>
      <template slot="price" slot-scope="text"> ￥ {{ (text / 100).toFixed(2) }} </template>
      <template slot="email" slot-scope="text, record">
        {{ record.user && record.user.email }}
      </template>
      <template slot="createdAt" slot-scope="text">
        {{ $comsys.dateformat(text) }}
      </template>
      <template slot="action" slot-scope="text, record">
        <router-link :to="`/ordersdetail/${record._id}`">
          <a @click="hanleSeeDetails(record)">查看详情</a>
        </router-link>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getOrderList } from '../../services/index'
const columns = [
  {
    title: '订单ID',
    dataIndex: 'orderId',
    key: 'orderId'
  },
  {
    title: '购买邮箱',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' }
  },
  {
    title: '作品数量',
    dataIndex: 'count',
    key: 'count',
    scopedSlots: { customRender: 'count' }
  },
  {
    title: '金额',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: '时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'createdAt' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'OrdersList',
  data() {
    return {
      form: this.$form.createForm(this),
      value: 1,
      data: [],
      columns,
      pagination: {
        pageSize: 10, // 默认每页显示数量
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onChange: this.pageChange, // 点击页码事件
        total: 0 // 总条数
      }
    }
  },
  mounted() {
    this.getList({
      type: 1
    })
  },
  methods: {
    getList(params) {
      getOrderList(params).then((res) => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagination.total = res.data.count
        }
      })
    },
    // 页数变化
    pageChange(page, pageSize) {
      this.getList({ currentPage: page, type: 1 })
    },
    hanleSeeDetails(record) {
      console.log(record._id)
    },
    // 重置搜索内容
    handleReset() {
      this.form.resetFields()
      this.getList({
        type: 1
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const params = { type: 1 }
        if (values.orderId) {
          params.orderId = values.orderId
        }
        if (values.email) {
          params.email = values.email
        }
        this.getList(params)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.OrdersList {
  padding: 24px;
}
</style>
